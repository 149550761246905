// Import the functions you need from the SDKs you need

import * as firebase from "firebase/app";
//import { getMessaging, onMessage } from "firebase/messaging";
//import { onBackgroundMessage } from "firebase/messaging/sw";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATFBKskCd7ElyQ_7i_qzSI7EQrdek04nw",
  authDomain: "mcsummary-c82fb.firebaseapp.com",
  projectId: "mcsummary-c82fb",
  storageBucket: "mcsummary-c82fb.appspot.com",
  messagingSenderId: "716756034282",
  appId: "1:716756034282:web:99603c7a8ce17f5fb4ad93",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//export default app;
