/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";

// Routes
import paths from "./paths";

function route(path, view, name, children, props) {
    return {
        name: name || view,
        path,
        component:
            path == "/"
                ? (resovle) => import(`@/${view}.vue`).then(resovle)
                : (resovle) => import(`@/views/${view}.vue`).then(resovle),
        props,
        children: children
            ? children.map((child) => {
                  return route(
                      child.path,
                      child.view,
                      child.name,
                      null,
                      child.props,
                  );
              })
            : [],
    };
}

Vue.use(Router);

let isValid = localStorage.getItem("isAuth") ? "/scottish" : "/scottish";

// Create a new router
const router = new Router({
    base: "/",
    mode: "history",
    routes: [{ path: "/", redirect: isValid }].concat(
        paths.map((path) =>
            route(path.path, path.view, path.name, path.children, path.props),
        ),
    ),

    scrollBehavior(to, from, savedPosition) {
        if (
            from.matched.length >= 2 &&
            to.matched.length == 1 &&
            from.matched.includes(to.matched[0])
        ) {
            console.log("2");
            return;
        }

        if (savedPosition) {
            console.log("3");
            return savedPosition;
        }
        if (to.hash) {
            console.log("4");
            return { selector: to.hash };
        }
        console.log("5");
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (localStorage.getItem("isAuth") && to.name == "login") {
        return router.push("/welcome");
    }
    // if (!localStorage.getItem("isAuth") && to.name != "login") {
    //     return router.push("/welcome");
    // }
    return next();
});

export default router;
