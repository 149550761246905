export default [
    {
        path: "/",
        view: "App",
        name: "app",
        children: [
            {
                path: "contact",
                view: "Contact",
                name: "contact",
            },
            {
                path: "about",
                view: "About",
                name: "about",
            },
            {
                path: "health",
                view: "Health",
                name: "health",
            },
            {
                path: "/home",
                view: "Welcome",
                name: "home",
            },
            {
                path: "/scottish",
                view: "Step0",
                name: "welcome",
                props: { market: "scottish" },
            },
            {
                path: "/english",
                view: "Step0",
                name: "welcome",
                props: { market: "english" },
            },
            {
                path: "/leads",
                view: "Leads",
                name: "leads",
            },
            {
                path: "/investigate",
                view: "DieselInvestigate",
                name: "diesel_investigate",
            },
            {
                path: "/mongo",
                view: "Mongo",
                name: "mongo",
            },
            {
                path: "/agreement",
                view: "Agreement",
                name: "agreement",
            },
        ],
    },
    {
        path: "/",
        view: "Blank",
        name: "blank",
        children: [
            // {
            //     path: "login",
            //     view: "Login",
            //     name: "login",
            //     props: { meta: { disallowAuthed: true } },
            // },
        ],
    },
];
