import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import API from "./service/api";
import router from "@/router";

Vue.use(Vuex);
const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

const formdataVersion = "1_0_9b";
export default new Vuex.Store({
  state: {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    currentStep: 1,
    barColor: "rgba(220, 220, 220, .8), rgba(255, 255, 255, .8)",
    barImage: "",
    drawer: true,
    wss: { status: "Loading", loading: true },

    health: {
      datasoap: { loading: true, data: {} },
      debounce: { loading: true, data: {} },
      postcoder: { loading: true, data: {} },
    },
    notifications: ["Credit Alert 1", "Ceedit Alert 2"],
    firebase_token: "",
    isAppLoading: false,
    isShowLogo: false,
    isInsideForm: false,
    searchPostCodeDialog: [],
    income_aboard: { period: "Monthly", amount: 0 },
    formDataDefault: {},
    formData: {
      market: "scottish",
      generator: { name: "hb_1_0_2b" },
      personal: {
        lastname: "",
        surname: "",
      },
      currentStep: 1,
      toggleBTGroup: "no",
      lines: [{}],
      creditors: [
        {
          creditors_name: null,
          balance: null,
          include_in_assessment: "yes",
          arrears: "no",
          against_property: "no",
        },
      ],
      employmentStatus: {},
      healthWellbeing: {},
      home: {
        ownproperty: "no",
        towardsavehicle: "no",
        in_arrears_with_your_rent_or_mortgage: "no",
      },
      assets: {
        over_1000: "no",
        outstanding_balance_against_property: "no",
        is_essential: "no",
      },
      income: { any_benefits: "no", is_pension: "no" },
      outgoings: { priority_bills: "no" },
      essentials: {},
      housekeeping: {},
      other_expenditures: {},
      emailChecker: { result: { responseData: { valid: false } } },
      mobileChecker: {},
    },
    api_response: {},
    mobileChecker: {},
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_WSS(state, payload) {
      state.wss = payload;
      state.wss.loading = false;
    },
    SET_HEATH(state, payload) {
      switch (payload.service) {
        case "datasoap":
          state.health.datasoap.loading = false;
          state.health.datasoap.data = payload.deliver;
          break;
        case "debounce":
          state.health.debounce.loading = false;
          state.health.debounce.data = payload.deliver;
          break;
        case "postcoder":
          state.health.postcoder.loading = false;
          state.health.postcoder.data = payload.deliver;
          break;
      }
    },
    ADD_NOTIFICATION(state, payload) {
      state.notifications.unshift(payload.message);
    },
    SET_FIREBASETOKE(state, payload) {
      state.firebase_token = payload.token;
    },
    SET_LOADING(state, payload) {
      state.isAppLoading = payload;
    },
    updateFormData(state, payload) {
      state.formData = { ...state.formData, ...payload };
    },
    RESET_FORM_DATA(state) {
      localStorage.removeItem("formProgress" + formdataVersion);
      state.formData = state.formDataDefault;
      state.formData.currentStep = 1;
    },
    setRemoveAddress(state, payload) {
      if (state.formData.lines.length > 1)
        state.formData.lines.splice(payload, 1);
    },
    setLogo(state, payload) {
      state.isShowLogo = payload;
    },
    setisInsideForm(state, payload) {
      state.isInsideForm = payload;
    },
    setRemoveCreditor(state, payload) {
      if (state.formData.creditors.length > 1)
        state.formData.creditors.splice(payload, 1);
    },
    SET_PCSEARCH(state, payload) {
      state.searchPostCodeDialog = payload;
    },
    SET_MOBILESEARCH(state, payload) {
      state.formData.mobileChecker = payload;
    },
    SET_EMAILSEARCH(state, payload) {
      state.formData.emailChecker = payload;
    },
    SET_PAGE_PREV(state) {
      state.formData.currentStep--;
      state.currentStep--;
    },
    SET_PAGE_NEXT(state) {
      state.formData.currentStep++;
      state.currentStep++;
    },
    SET_CLONE_FORMDATA(state) {
      state.formDataDefault = state.formData;
    },
    SET_API_RESPONSE(state, payload) {
      state.formData.id = payload.id;
      state.api_response = payload;
    },
    UPDATE_UTM(state, payload) {
      state.utm_term = payload.utm_term;
      state.utm_medium = payload.utm_medium;
      state.utm_source = payload.utm_source;
      state.utm_campaign = payload.utm_campaign;
    },
    UPDATE_MARKET(state, payload) {
      state.formData.market = payload == `scottish` ? `scottish` : `english`;
    },
  },
  actions: {
    USER_AUTH: function ({ commit }) {
      if (!localStorage.getItem("isAuth")) {
        router.push({ name: "home" });
      }
    },
    async fetchWSS({ commit }) {
      try {
        //await delay(3000);
        const data = await axios.get(
          "https://marketingapi.vercel.app/check_website",
          { withCredentials: false },
        );
        commit("SET_WSS", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDataSoap({ commit }) {
      try {
        //await delay(3000);
        const data = await axios.get(
          "https://marketingapi.vercel.app/datasoap",
          { withCredentials: false },
        );
        commit("SET_HEATH", { service: "datasoap", deliver: data.data });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDebounce({ commit }) {
      try {
        //await delay(3000);
        const data = await axios.get(
          "https://marketingapi.vercel.app/debounce",
          { withCredentials: false },
        );
        commit("SET_HEATH", { service: "debounce", deliver: data.data });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPostcoder({ commit }) {
      try {
        //await delay(3000);
        const data = await axios.get(
          "https://marketingapi.vercel.app/postcoder",
          { withCredentials: false },
        );
        commit("SET_HEATH", { service: "postcoder", deliver: data.data });
      } catch (error) {
        console.log(error);
      }
    },
    startAppLoading: function ({ commit }) {
      commit("SET_LOADING", true);
    },
    endAppLoading: function ({ commit }) {
      commit("SET_LOADING", false);
    },
    async pageAppLoading({ commit }, payload) {
      commit("SET_LOADING", true);
      const minDelay = 200; // 0.5 seconds in milliseconds
      const maxDelay = 200; // 3 seconds in milliseconds

      // Generate a random delay between minDelay and maxDelay
      const randomDelay =
        Math.floor(Math.random() * (maxDelay - minDelay + 1)) + minDelay;
      await new Promise((resolve) => setTimeout(resolve, randomDelay));

      setTimeout(() => {
        commit("SET_LOADING", false);
        payload.callback();
      }, randomDelay);
    },
    saveFormProgress({ commit, state }) {
      localStorage.setItem(
        "formProgress" + formdataVersion,
        JSON.stringify(state.formData),
      );
      //console.log("form saved", state.formData);
    },

    loadFormProgress({ commit }) {
      // Load the form data from localStorage or a backend server
      const savedData = localStorage.getItem("formProgress" + formdataVersion);
      if (savedData) {
        commit("updateFormData", JSON.parse(savedData));
      }
    },
    carbonCopy({ commit }) {
      commit("SET_CLONE_FORMDATA");
    },
    clearFormProgress({ commit }) {
      commit("RESET_FORM_DATA");
    },
    removeAddress({ commit, state }, payload) {
      console.log(payload);
      commit("setRemoveAddress", payload);
    },
    showLogo({ commit }) {
      commit("setLogo", true);
    },
    hideLogo({ commit }) {
      commit("setLogo", false);
    },
    showInsideForm({ commit }) {
      commit("setisInsideForm", true);
    },
    hideInsideForm({ commit }) {
      commit("setisInsideForm", false);
    },
    removeCreditor({ commit, state }, payload) {
      commit("setRemoveCreditor", payload);
    },
    async searchPostcode({ commit }, payload) {
      try {
        //await delay(3000);
        commit("SET_PCSEARCH", []);
        const data = await axios.post(
          "https://hpbackend.vercel.app/api/toga",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "your-static-api-key",
            },
            withCredentials: false,
          },
        );
        commit("SET_PCSEARCH", data.data.result.responseData);
      } catch (error) {
        console.log(error);
      }
    },
    async searchMobile({ commit }, payload) {
      try {
        //await delay(3000);
        commit("SET_MOBILESEARCH", {});
        const data = await axios.post(
          "https://hpbackend.vercel.app/api/toga",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "your-static-api-key",
            },
            withCredentials: false,
          },
        );
        console.log(data);
        commit("SET_MOBILESEARCH", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async searchEmail({ commit }, payload) {
      try {
        //await delay(3000);
        commit("SET_EMAILSEARCH", {});
        const data = await axios.post(
          "https://hpbackend.vercel.app/api/toga",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "your-static-api-key",
            },
            withCredentials: false,
          },
        );
        console.log(data);
        commit("SET_EMAILSEARCH", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    updateFormData({ commit }, payload) {
      commit("updateFormData", payload);
    },
    updateUTM({ commit }, payload) {
      commit("UPDATE_UTM", payload);
    },
    updateMarket({ commit }, payload) {
      commit("UPDATE_MARKET", payload.market);
    },
    prevPage({ commit }) {
      commit("SET_PAGE_PREV");
    },
    nextPage({ commit }) {
      commit("SET_PAGE_NEXT");
    },
    postData({ commit }, payload) {
      // Commit "SET_LOADING" mutation with true to indicate loading start
      commit("SET_LOADING", true);

      // Create a Promise to handle the asynchronous operation
      return new Promise((resolve, reject) => {
        // Make the POST request using Axios
        axios
          .post("https://hpbackend.vercel.app/api/submit", payload, {
            headers: {
              "x-api-key": "your-static-api-key", // Add your custom header
            },
          })
          .then((response) => {
            // Handle the response as needed
            // For example, commit a mutation to update state with the response data
            commit("SET_API_RESPONSE", response.data);

            // Commit "SET_LOADING" mutation with false to indicate loading complete
            commit("SET_LOADING", false);
            payload.callback();
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch((error) => {
            // Handle errors
            console.error("Error in postData action:", error);

            // Commit "SET_LOADING" mutation with false in case of an error
            commit("SET_LOADING", false);

            // Reject the Promise with the error
            reject(error);
          });
      });
    },
  },
});
