<template>
  <v-app>
    <CoreView />
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import CoreView from "./components/Core/View.vue";

export default {
  name: "Blank",
  components: {
    CoreView,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
};
</script>
